/* TokenMigration.css - Night Mode */
.token-migration-container {
    background-color: #1f2937; /* Darker background */
    border-radius: 20px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    font-family: 'Inter', sans-serif;
    margin: 20px auto;
    padding: 2rem;
    width: 600px;
    max-width: 100%;
}

.token-migration-header {
    color: #e5e7eb; /* Lighter text for dark mode */
    font-size: 1.25rem; /* text-2xl */
    font-weight: 600; /* font-semibold */
    margin-bottom: 1rem;
    text-align: center;
}

.text-gray-600, .text-gray-700 {
    color: #d1d6dc; /* Adjusted for dark mode readability */
}

.text-center {
    text-align: center;
}

.mt-4 {
    margin-top: 1rem;
}

.w-full {
    width: 100%; /* Ensures full width for block elements */
}

.px-4, .py-2 {
    padding: 0.5rem 1rem;
}

.bg-blue-500 {
    background-color: #2563eb; /* Adjusted blue for better visibility */
}

.bg-blue-400:hover, .bg-blue-500:hover {
    background-color: #3b82f6;
}

.bg-green-500 {
    background-color: #10b981; /* Green for success buttons */
}

.bg-green-400:hover, .bg-green-500:hover {
    background-color: #34d399;
}

.text-white {
    color: #f3f4f6; /* Light text for readability */
}

.rounded-md {
    border-radius: 15px;
}

.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.border {
    border: 1px solid #374151; /* Adjusted border color for dark mode */
}

.bg-white {
    background-color: #2d3748; /* Darker elements background */
}

.text-sm {
    font-size: 0.875rem;
}

.text-blue-600 {
    color: #93c5fd; /* Adjusted for visibility */
}

.bg-blue-100 {
    background-color: #1e40af; /* Darker blue background */
}

.text-green-600 {
    color: #bbf7d0; /* Bright green for contrast */
}

.bg-green-100 {
    background-color: #065f46; /* Darker green background */
}

.text-red-600 {
    color: #fecaca; /* Lighter red for contrast */
}

.bg-red-100 {
    background-color: #7f1d1d; /* Darker red background */
}

.rounded-full {
    border-radius: 9999px;
}

.rounded-lg {
    border-radius: 15px;
}

/* Additional utility classes for inputs and buttons */
input, select {
    border: 1px solid #4b5563; /* Adjusted for dark mode */
    padding: 0.5rem;
    width: calc(100% - 1rem); /* Adjust width to match select */
    margin-bottom: 1rem;
    border-radius: 16px;
    font-size: 1em;
    background-color: #2d3748; /* Dark mode input background */
    color: #e5e7eb; /* Dark mode input text */
}

input:focus, select:focus {
    border-color: #6366f1; /* Focus border color for dark mode */
    outline: none;
}

button {
    display: inline-block;
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    cursor: pointer;
    width: 100%;
    margin-bottom: 0.5rem;
    border: none; /* Remove border for buttons */
}

button:last-child {
    margin-bottom: 0; /* No margin for the last button */
}

button.border, button.border-gray-300 {
    background-color: #4b5563; /* Adjusted for button backgrounds */
    color: #e5e7eb; /* Adjusted for button text */
}

button.border:hover, button.border-gray-300:hover {
    background-color: #6b7280; /* Hover state for buttons */
}

/* Responsive Design */
@media (max-width: 768px) {
    .token-migration-container {
        padding: 1rem;
        width: auto;
    }
}
