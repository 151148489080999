body {
    background-color: #282c34;
    color: #ffffff;
}

.about-container {
    max-width: 960px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
}

.intro-section,
.details-section {
    background-color: #2bdc;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.intro-section h1,
.details-section h2 {
    color: #333;
    margin-bottom: 15px;
}

.details-section h3 {
    margin-top: 20px;
    color: #333;
}

ul {
    list-style: inside;
}

li {
    margin-bottom: 10px;
}

a {
    color: #007bff;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

button {
    padding: 12px;
    border-radius: 16px;
    border: 1px solid #ccc;
    font-size: 1em;
    cursor: pointer;
    background-color: #4084BF;
    color: white;
    border: none;
    transition: background-color 0.3s ease;
}

@media (max-width: 768px) {
    .about-container {
        padding: 10px;
    }

    .intro-section,
    .details-section {
        padding: 15px;
    }
}